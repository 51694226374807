import IC_AI from './ic_ai.svg';
import IC_audio from './ic_audio.svg';
import IC_Document from './ic_document.svg';
import IC_Excel from './ic_excel.svg';
import IC_File from './ic_file.svg';
import IC_folder from './ic_folder.svg';
import IC_img from './ic_img.svg';
import IC_txt from './ic_txt.svg';
import IC_zip from './ic_zip.svg';
export { IC_AI, IC_audio, IC_Document, IC_Excel, IC_File, IC_folder, IC_img, IC_txt, IC_zip };
